import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot } from '@angular/router';
import { IsrService } from '@rx-angular/isr/browser';
import { GetOfferGQL, GetOfferQuery } from 'generated/types.graphql-gen';
import { EMPTY, of, switchMap } from 'rxjs';

export const offerResolver: ResolveFn<GetOfferQuery | never> = (
  route: ActivatedRouteSnapshot,
) => {
  const isrService = inject(IsrService);

  return inject(GetOfferGQL)
    .fetch({
      slug: route.params['slug'],
      site: route.data['site'],
    })
    .pipe(
      switchMap((result) => {
        if (route.outlet === 'aside') {
          isrService.addError({
            name: 'Outlet route',
            message: 'No cache while in an outlet route.',
          } as Error);
        }

        if (result.data.entry) {
          return of(result.data);
        } else {
          isrService.addError({
            name: 'Offer does not exist',
            message: 'This page does not exist',
          } as Error);
          return EMPTY;
        }
      }),
    );
};
